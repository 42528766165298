/** @format */

import React from 'react';
import ProjectBar from '../components/ProjectBar';

import Layout from '../components/layout';
import SmallPhoto from '../components/SmallPhoto';
import Photo from '../components/Photo';
// import Text from "../components/Text"
import Title from '../components/Title';
// import Test from '../components/test.md';
import bayouVermillion from '../images/bayouvermillion.png';
import nugg from '../images/nuggmock.png';
import arbys from '../images/arbys.png';
import dhl1 from '../images/dhl1.png';
import windsor from '../images/windsor.png';
import q6 from '../images/q6.png';
import candc from '../images/cardsandculture.png';

import { navigate } from 'gatsby-link';

import lu from '../images/liveunlimitedc.png';
import mb from '../images/mallardbayc.png';
import cms from '../images/cybercmscover.png';
import dhl from '../images/dhl1.png';

const OtherWork = () => {
    return (
        <Layout>
            {/* <ProjectBar /> */}

            {/* <div className="section" id="content-desktop">
                <div className="container">
                    <div className="project-bar-container">
                        <div
                            className="project-container"
                            onClick={() => navigate(`/liveunlimited`)}>
                            <img className="project-photo" src={lu}></img>
                            <div className="project-name">Live Unlimited</div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/cybercms/`)}>
                            <img className="project-photo" src={cms}></img>
                            <div className="project-name">Cyber CMS</div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/mallardbay/`)}>
                            <img className="project-photo" src={mb}></img>
                            <div className="project-name">Mallard Bay</div>
                        </div>
                        <div
                            className="project-container-active"
                            onClick={() => navigate(`/otherwork/`)}>
                            <img className="project-photo" src={dhl}></img>
                            <div className="project-name">Other Work</div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="section">
                <div className="container">
                    <div className="title-wrapper">
                        <div
                            onClick={() => {
                                navigate('/#work');
                            }}
                            className="back">
                            <div className="subtitle">
                                <b>←</b> Back to Projects
                            </div>
                        </div>
                        {/* <div className="categories">{props.categories}</div> */}
                        <h1>Other work</h1>
                        <h3>
                            A collection of website, app, and one-off designs.
                        </h3>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="title-wrapper">
                        <hr></hr>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="title-wrapper">
                        <h3 className="">
                            UI/UX Design, Front-End Development
                        </h3>
                        <h1>DH Labs Website</h1>
                        <p>
                            Website for an app building business I started after
                            college
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={dhl1} subtitle="Homepage" />
            {/* <Title
                title="Q6"
                subtitle="Video review app. Like Yelp, but 12 second videos."
                categories="UI/UX Design"
            />

            <SmallPhoto
                link={q6}
                subtitle="Explore local businesses, leave reviews, and get them rectified!"
            /> */}
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper"></div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="title-wrapper">
                        <h3 className="">UI/UX Design</h3>
                        <h1>Bayou Vermillion Canoing POI App</h1>
                        <p>
                            Learn about the Bayou Vermillion District and find
                            interesting destinations and events along the Bayou
                            Vermillion. Designed to be used while kayaking down
                            the bayou. Available in English and en français.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto
                link={bayouVermillion}
                subtitle="Homepage, Page D'Accueil, Map View, & POI View"
            />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper"></div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>
                    </div>
                </div>
            </div>
            {/* <Title
                title="Cards and Culture App"
                subtitle="Unreleased application for sports card and memorabilia investing."
                categories="UI/UX Design"
            /> */}
            <div className="section">
                <div className="container">
                    <div className="title-wrapper">
                        <h3 className="">UI/UX Design</h3>
                        <h1>Cards and Culture</h1>
                        <p>
                            Unreleased application for sports card and
                            memorabilia investing.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto
                link={candc}
                subtitle="View and grow your sports card portfolio's value."
            />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper"></div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="title-wrapper">
                        <h3 className="">UI Design</h3>
                        <h1>Windsor Group</h1>
                        <p>Website redesign for Windsor Group LLC</p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={windsor} subtitle="" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper"></div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="title-wrapper">
                        <h3 className="">UI Design</h3>
                        <h1>NuggSwap</h1>
                        <p>
                            Design for a chicken nugget themed cryptocurrency
                            swap
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={nugg} subtitle="Welcome to NuggSwap" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper"></div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="title-wrapper">
                        <h3 className="">UI/UX Design</h3>
                        <h1>Arby's</h1>
                        <p>
                            An app I made to practice app development, it points
                            you in the direction of the nearest Arby's... what
                            more could you want?
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto
                link={arbys}
                subtitle="The most useful app in existence"
            />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3></h3>
                        <p></p>
                    </div>
                </div>
            </div>
            {/* <ProjectBar /> */}

            <div className="section" id="content-desktop">
                <div className="container">
                    <div className="project-bar-container">
                        <div
                            className="project-container"
                            onClick={() => navigate(`/liveunlimited`)}>
                            <img className="project-photo" src={lu}></img>
                            <div className="project-name">Live Unlimited</div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/cybercms/`)}>
                            <img className="project-photo" src={cms}></img>
                            <div className="project-name">Cyber CMS</div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/mallardbay/`)}>
                            <img className="project-photo" src={mb}></img>
                            <div className="project-name">Mallard Bay</div>
                        </div>
                        <div
                            className="project-container-active"
                            onClick={() => navigate(`/otherwork/`)}>
                            <img className="project-photo" src={dhl}></img>
                            <div className="project-name">Other Work</div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default OtherWork;
